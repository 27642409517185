import { html, render } from "lit-html";
import { IDetailedCartInfo } from "../interfaces/detailed-cart-info.js";
import { cartEventSubscriber } from "../../../common/pubsub/cart-event-pub-sub.js";

export class CartBtnV1 extends HTMLElement {
    constructor() {
        super();
    }

    /* #region Markup */
    private template = (prodCount: number) => html`
        <a href="${window.cartSettings.detailedCartPageUrl}" @click="${this._onCartBtnClick.bind(this)}">
            <div class="prod-count">${prodCount}</div>
        </a>
    `;
    /* #endregion */

    /* #region Private */
    private _clickAction: string;

    get clickAction(){
        this._clickAction = this.getAttribute('clickaction');
        return this._clickAction;
    }

    private _onCartBtnClick(e: MouseEvent) {
        switch (this.clickAction) {
            case ClickAction.PreventDefault:
                e.preventDefault();
                // Explicitly set to do nothing.
                // This doens't create strong relation between components and other components can use this state to run their own action.
                // In use by cart-sidebar-v1 webcomponent at creation date.
                break;
            case ClickAction.OpenDetailedCartPage:
            default:
                window.location.href = window.cartSettings.detailedCartPageUrl;
                break;
        }
    }

    private _updateView(data: IDetailedCartInfo) {
        render(this.template(data.summary.productCount), this);
    }

    private async _onSiteCartChanged(topic: string, data: IDetailedCartInfo){
        this._updateView(data);
    }
    /* #endregion */

    public connectedCallback() {
        if(this.isConnected){
            cartEventSubscriber.onSiteCartChanged(this._onSiteCartChanged.bind(this));
        }
    }
}

customElements.define('cart-btn-v1', CartBtnV1);

enum ClickAction {
    PreventDefault = 'preventdefault',
    OpenDetailedCartPage = 'opendetailedcartpage'
}